<template>
  <section class="my--listing-listings">
    <div v-for="listing in shortlists" :key="listing.id" class="favorite--listing-item">
      <card-listing :listing="listing" :show-compare="false" :allowMultiImage="true" />
    </div>
  </section>
</template>

<script>
const CardListing = () => import('@/components/utils/card-listing');
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    CardListing,
  },
  data() {
    return {
      listings: [],
    };
  },
  computed: {
    ...mapGetters({
      shortlists: 'v2/listing/listings',
    }),
    ...mapState({
      meta: state => state.v2.listing.meta,
      isLoading: state => state.global.isLoading,
    }),
  },
};
</script>
