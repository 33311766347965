<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <dashboard-skeleton :page-loading="loading">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('dashboard.favorite') }}
      </div>
      <div class="skeleton--container">
        <favorites-header />
        <favorites />
      </div>
    </dashboard-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DashboardSkeleton from '@/components/dashboard/dashboard-skeleton';
import FavoritesHeader from '@/components/dashboard/favorites/listings-header';
import Favorites from '@/components/dashboard/favorites/listings';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapGetters, mapState } from 'vuex';

// const Pagination = () => import('@/components/utils/fractal-pagination.vue');
export default {
  mixins: [DefaultHeadMixin],
  components: {
    // Pagination,
    DefaultLayout,
    FavoritesHeader,
    Favorites,
    DashboardSkeleton,
  },
  data() {
    return {
      checkBankAccountDone: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      listingType: state => state.myShortlist.listingType,
      shortlists: 'v2/listing/listings',
      meta: state => state.v2.listing.meta,
    }),
    ...mapGetters({
      // favoritesActiveSortBy: 'myListing/activeSortBy',
      // favoritesCategories: 'myListing/allowedCategory',
    }),
    breadcrumbs() {
      const currentName = this.$t('dashboard.favorite');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {
    async getFavorites() {
      try {
        this.loading = true;
        await this.$store.dispatch('v2/listing/getMyShortlists', this.listingType);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$store.commit('myListing/SET_ACTIVE_SIDEBAR', false);
    },
  },
  async mounted() {
    this.$store.commit('myListing/SET_ACTIVE_SIDEBAR', true);
    await this.$store.dispatch('v2/search/getShortlists');
    await this.getFavorites();
  },
};
</script>
