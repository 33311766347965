<template>
  <section class="my--listing-header">
    <div class="category--container" v-if="$mq !== 'xs'">
      <router-link class="btn btn-primary--outline" to="/dashboard/mylisting">
        {{ $t('dashboard.myListing') }}
      </router-link>
      <!--      <router-link class="btn btn-primary&#45;&#45;outline" to="/dashboard/mybooking">-->
      <!--        {{ $t('dashboard.myBooking') }}-->
      <!--      </router-link>-->
      <router-link class="btn btn-primary" to="/dashboard/favorites">
        {{ $t('dashboard.favorite') }}
      </router-link>
    </div>
    <div class="dashboard--search-container mb-0">
      <div class="dropdown--wrapper">
        <rent-sell-switch
          :listingType="listingType"
          @changeType="inputListingTypeHandler"
        ></rent-sell-switch>
        <!--        <v-select-->
        <!--          :value="listingType"-->
        <!--          @input="inputListingTypeHandler"-->
        <!--          :items="listingTypes"-->
        <!--          :hint="`${$t('addListing.listingType')} ${$t(`general.filter.${listingType}`)}`"-->
        <!--          class="basic&#45;&#45;dropdown"-->
        <!--          persistent-hint-->
        <!--          single-line-->
        <!--        >-->
        <!--          <template slot="selection" slot-scope="{ item }">-->
        <!--            {{ $t(`general.filter.${item}`) }}-->
        <!--          </template>-->
        <!--          <template slot="item" slot-scope="{ item }">-->
        <!--            {{ $t(`general.filter.${item}`) }}-->
        <!--          </template>-->
        <!--        </v-select>-->
      </div>
    </div>
  </section>
</template>

<script>
// import SearchListing from '@/components/dashboard/my-listing/search-listing';
const RentSellSwitch = () => import('@/components/utils/rent-sell-switch');
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    RentSellSwitch,
  },
  computed: {
    ...mapState({
      sortBys: state => state.myListing.sortByNavList,
      listingType: state => state.myShortlist.listingType,
      listingTypes: state => state.global.listingTypes,
    }),
    ...mapGetters({
      categories: 'myListing/allowedCategory',
      activeSortBy: 'myListing/activeSortBy',
      activeCategoryObject: 'myListing/activeCategoryObject',
    }),
  },
  methods: {
    inputListingTypeHandler(value) {
      this.$store.commit('myShortlist/SET_LISTING_TYPE', value);
      this.$store.dispatch('myShortlist/changeListingType');
    },
  },
};
</script>
